import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import { HomeSectionQueryQuery } from "../../graphql"
import FullScreenComponent from '../full-screen-component'
import FullScreenContainer from '../full-screen-container'
import LuchadorImage from '../luchador-image'
import {scrollTo} from "../../utilities/luchador-utils"
import { aspectRatioMediaQueries, breakpoints, mediaQueries } from "../../styles/mediaQueries"

interface HomeProps {
    onLoaded: () => void
}


const imagesToLoad = 6;
let imagesLoaded = 0;

const Home = ({onLoaded}: HomeProps) => {
    const { background, hecho, cans, text, bottle1, bottle2, bottle3, bottle4 }: HomeSectionQueryQuery = useStaticQuery(
        graphql`
            query HomeSectionQuery {
                background: file(
                    relativePath: { eq: "home/MAIN_BG_01.jpg"}
                ) {
                    ...backgroundImage
                },
                text: file(
                    relativePath: { eq: "home/HOMEPAGE_TEXT_x2.png"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 1383) {
                            src
                        }
                    }
                },
                hecho: file(
                    relativePath: { eq: "home/HOMEPAGE_HECHO_STAMP_x2.png"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 546) {
                            src
                        }
                    }
                },
                cans: file(
                    relativePath: { eq: "home/HOMEPAGE_3CANS_x2.png"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 1853) {
                            src
                        }
                    }
                },
                bottle1: file(
                    relativePath: { eq: "section-2/BOTTLE_Blanco_02.png"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 709) {
                            src
                        }
                    }
                }
                bottle2: file(
                    relativePath: { eq: "section-3/BOTTLE_Reposado_02.png"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 893) {
                            src
                        }
                    }
                },
                bottle3: file(
                    relativePath: { eq: "section-4/BOTTLE_Anejo_02.png"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 709) {
                            src
                        }
                    }
                },
                bottle4: file(
                    relativePath: { eq: "section-5/BOTTLE_110proof_02.png"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 709) {
                            src
                        }
                    }
                },
            }
        `,
    )

    const [allImagesLoaded, setAllImagesLoaded] = React.useState(false)
    const handleImageLoaded = () => {
        imagesLoaded++;
        if (imagesLoaded >= imagesToLoad && !allImagesLoaded) {
            setAllImagesLoaded(true);
            onLoaded && onLoaded();
        }
    }
    return (
        <StyledHome id="home"  backgroundImage={background.childImageSharp.fluid.src}>
            <LoadContainer visible={allImagesLoaded}>
            <ImageContainer>
                <TextImageContainer>
                    <TextImage onLoaded={handleImageLoaded} image={text} />
                </TextImageContainer>
                <BottleImageContainer>
                    <BottleImage
                        onClick={() => scrollTo('section-2')}
                    >
                    <LuchadorImage onLoaded={handleImageLoaded} image={bottle1} />
                    </BottleImage>
                    <BottleImage
                        onClick={() => scrollTo('section-3')}><LuchadorImage onLoaded={handleImageLoaded} image={bottle2} /></BottleImage>
                    <BottleImage
                        onClick={() => scrollTo('section-4')}><LuchadorImage onLoaded={handleImageLoaded} image={bottle3} /></BottleImage>
                    <BottleImage
                        onClick={() => scrollTo('section-5')}><LuchadorImage onLoaded={handleImageLoaded} image={bottle4} /></BottleImage>
                </BottleImageContainer>
                <HechoImageContainer>
                    <HechoImage onLoaded={handleImageLoaded} className="hecho-LuchadorImage" image={hecho} />
                </HechoImageContainer>
                <Spacer />
            </ImageContainer>
            </LoadContainer>
        </StyledHome>
    )
}

const StyledHome = styled(FullScreenContainer)`
    background-position: top right;
    height: 100vh;
    background-position: center -27vh;
    background-size: 147%;

    @media (max-width: ${breakpoints['maxHeadline']}px) { 
        background-position: center;
        background-size: cover;
    };

    ${aspectRatioMediaQueries("homeBreak")`
        padding-left: 80px;
    `};

    ${mediaQueries("tablet")`
        padding-left: 0;
    `};
`

const LoadContainer = styled.div`
    width: 100vw;
    height: 100vh;
    display: block;
    transition: opacity 0.5s linear;
    opacity: ${props => props.visible ? 1 : 0};
`

const ImageContainer = styled(FullScreenComponent)`
    align-items: flex-end;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    left: 50%;
    padding-bottom: 0;
    padding-top: 0;
    position: relative;
    transform: translate(-50%);
    width: auto;

    ${aspectRatioMediaQueries("homeBreak")`
        max-width: 100%;
    `};

    ${mediaQueries("tablet")`
        padding-left: 0;
        padding-right: 0;
    `};
`

const BottleImageContainer = styled.div`
    display: flex;
    flex: 0 1 80%;
    width: calc(80vh / 0.76);
    justify-content: center;
    position: relative;
    object-position: top;
    z-index: 1;

    ${aspectRatioMediaQueries("homeBreak")`
        max-width: 100%;
        flex: 0 1 35%;
    `};

    ${aspectRatioMediaQueries("homeBreak")`
        margin-bottom: 0;
        transform: translateX(-0.75%);
    `};

`

const BottleImage = styled.div`
    flex: 0 0 25%;
    cursor: pointer;

    &:nth-child(1) {
        transform: translateX(25%);
    }

    &:nth-child(2) {
        transform: translateX(13%);
    }

    &:nth-child(4) {
        transform: translateX(-16%);
    }
`

const Spacer = styled.div`
    flex: 1 1 0%;
    width: 100%;

    ${aspectRatioMediaQueries("homeBreak")`
        flex: 0;
    `};
`

const TextImageContainer = styled.div`
    flex: 1 1 15%;
    height: 15%;
    min-height: 100px;
    position: relative;
    width: 100%;
    z-index: 0;

    ${aspectRatioMediaQueries("homeBreak")`
        display: flex;
        flex: 1;
    `};
`

const HechoImageContainer = styled.div`
    flex: 0;
    height: 0%;
    position: relative;
    width: 100%;
    z-index: 0;

    ${aspectRatioMediaQueries("homeBreak")`
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    `};

`
const HechoImage = styled(LuchadorImage)`
    top: 0;
    left: -5vh;
    position: absolute;
    max-height: 25vh;
    height: 25vh;
    object-position: bottom;
    transform: translate(-78%,calc(-141% - 5vh)) rotate(-30deg);
    max-width: 200px;
    width: auto;
    
    ${aspectRatioMediaQueries("homeBreak")`
        position: relative;
        left: initial;
        transform: none;
        max-height: calc(100% - 40px);
        max-width: 35vw;
        height: auto;
        margin: 20px;
    `};
`

const TextImage = styled(LuchadorImage)`
    bottom: 0;
    left: 0;
    position: absolute;
    max-height: 33vh;
    height: 33vh;
    object-position: bottom;
    transform: translate(-41%,53%);
    max-width: 691px;
    width: auto;
    
    @media (max-width: ${breakpoints['maxHeadline']}px) { 
        ${aspectRatioMediaQueries("homeLogoBreak")`
            height: 27vh;
            transform: translate(calc(87px + (-1 * ((100vw - 790px) / 2))),40%);
        `};
    };
    
    ${aspectRatioMediaQueries("homeBreak")`
        position: relative;
        bottom: initial;
        margin: auto;
        max-width: 75%;
        max-height: 100%;
        height: auto;
        transform: translate(-2.5%, 5%);
    `};
`

export default Home
