import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import luchadorMp3 from "../audio/luchador.mp3"
import blog from "../images/header/blog.png"
import facebook from "../images/header/facebook.png"
import grit from "../images/header/grit.png"
import info from "../images/header/info.png"
import instagram from "../images/header/instagram.png"
import pause from "../images/header/pause-btn.png"
import play from "../images/header/play-btn.png"
import twitter from "../images/header/twitter.png"
import "../styles/lib/hamburgers.min.css"
import { mediaQueries } from "../styles/mediaQueries"
import { StyledH1, StyledP } from "../styles/typography"
import { useWindowResize } from "../utilities/hooks"
import { scrollTo } from "../utilities/luchador-utils"
import FullScreenContainer from "./full-screen-container"
import LuchadorImage from "./luchador-image"
import Modal from "./modal"

import mezcalLogo from "../images/header/mezcal-craneo-logo.svg"
import oneTwoThreeLogo from "../images/header/123-tequila-logo.svg"
import buyButton from "../images/header/buy-button.png"

const StyledHeader = styled.header`
  background: rgba(255, 255, 255, 0.5);
  height: 100%;
  left: 30px;
  position: fixed;
  top: 0;
  width: 50px;
  z-index: 1;
  transition: transform 0.5s cubic-bezier(0, 0, 0, 0.5);
  overflow: visible;

  .hamburger {
    position: absolute;
    top: 0;
    left: 100%;
    -webkit-appearance: div;
    outline: 0;

    &-inner {
      background-color: black;
      &:before,
      &:after {
        background-color: black;
      }
    }

    &.is-active {
      .hamburger-inner {
        background-color: black;
        &:before,
        &:after {
          background-color: black;
        }
      }
    }
  }

  ${mediaQueries("tablet")`
    left: initial;
    right: 100%;
  `};
`

const HeaderSections = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  flex-direction: column;
  overflow-y: auto;
  padding: 50px 0;
  width: 100%:

`

const Section = styled.div`
  font-family: "Volcano";
  font-size: 36px;
  padding: 4px;
`

const Circle = styled.div`
  align-items: center;
  border-radius: 21px;
  cursor: pointer;
  display: flex;
  background-image: url(${grit});
  background-color: black;
  background-repeat: repeat;
  background-size: cover;
  height: 42px;
  justify-content: center;
  margin: 12px 0;
  width: 42px;

  &:hover {
    background-color: #cc092f;
  }

  &:first-child {
    background-position-x: 10px;
  }

  &:nth-child(2) {
    background-position-x: -20px;
  }

  &:nth-child(3) {
    background-position-x: 40px;
  }

  &:nth-child(4) {
    background-position-x: -50px;
  }

  img {
    width: 50%;
    height: 50%;
    object-fit: contain;
    margin: 0;
  }
`

const SectionMask = styled.p`
  font-size: 48px;
  margin: 16px auto;
  cursor: pointer;
  line-height: 1em;
  text-align: center;

  &:hover,
  &.active {
    color: #cc092f;
  }
`

const MusicButton = styled.img`
  cursor: pointer;
  height: auto;
  margin: 0.5em auto 0;
  width: 25px;
`

const StyledModalSection = styled(FullScreenContainer)`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  min-width: 100%;
  min-height: 100%;
  max-height: calc(100vh - 80px);
  overflow: hidden;
  max-width: calc(80vw - 80px);
  overflow: scroll;
  padding: 32px;

  ${mediaQueries("maxHeadline")`
        flex-direction: column;
        justify-content: flex-start;
        flex-wrap: nowrap;
    `};

  ${mediaQueries("tablet")`
      max-width: calc(100vw - 80px);
    `};
`

const ModalSection = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
`

const MailLink = styled.a`
  color: black;
  display: block;
  &:hover {
    color: grey;
    p {
      color: grey;
    }
  }
`

const CircleButton = ({ src }) => {
  return (
    <Circle>
      <img src={src} />
    </Circle>
  )
}

const ImgButton = styled.img`
  margin: 12px 0;

  &:hover {
    opacity: 0.75;
  }
`

const sectionChars = ["L", "O", "X", "P", "B", "J"]

const Header = () => {
  const { paper, masthead }: HeaderQuery = useStaticQuery(
    graphql`
      query Header {
        paper: file(relativePath: { eq: "section-6/modals/paper.png" }) {
          childImageSharp {
            fluid(maxWidth: 1502) {
              src
            }
          }
          placeholder: childImageSharp {
            fluid(base64Width: 1502, pngCompressionSpeed: 1, quality: 50) {
              base64
            }
          }
        }
        masthead: file(relativePath: { eq: "header/luchador-masthead.png" }) {
          childImageSharp {
            fluid(maxWidth: 1158) {
              src
            }
          }
        }
      }
    `,
  )

  const audioRef = React.createRef<HTMLAudioElement>()
  const [playing, setPlaying] = React.useState(false)
  const [infoOpen, setInfoOpen] = React.useState(false)
  const [activeSection, setActiveSection] = React.useState(undefined)
  const [mobileMenuActive, setMobileMenuActive] = React.useState(false)
  const windowSize = useWindowResize()
  const isSmall = windowSize.width <= 768

  const toggleMusic = () => {
    if (playing) {
      turnOffMusic()
    } else {
      turnOnMusic()
    }
  }

  const turnOffMusic = () => {
    setPlaying(false)
  }

  const turnOnMusic = () => {
    setPlaying(true)
  }

  React.useEffect(() => {
    var music = audioRef.current
    music.volume = 0.1
    if (playing) {
      music.play()
    } else {
      music.pause()
    }
  }, [playing])

  const sections =
    typeof document !== `undefined`
      ? document.querySelectorAll('*[id^="section-"]')
      : []

  const homeSection =
    typeof document !== `undefined`
      ? document.getElementById("home")
      : undefined

  React.useEffect(() => {
    const observers = []

    //home section
    let homeObserver
    if (homeSection) {
      homeObserver = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting === true) {
            setActiveSection(undefined)
          }
        },
        { threshold: [0.5] },
      )

      homeObserver.observe(homeSection)
    }

    //section observers
    sections.forEach((_section, i) => {
      observers.push(
        new IntersectionObserver(
          (entries) => {
            if (entries[0].isIntersecting === true) {
              setActiveSection(i)
            }
          },
          { threshold: [0.5] },
        ), // 1 doesn't seem to work, not sure why
      )
    })

    observers.forEach((observer, i) => {
      observer.observe(sections[i])
    })

    return () => {
      observers.forEach((observer, i) => observer.unobserve(sections[i]))
      if (homeObserver) {
        homeObserver.unobserve(homeSection)
      }
    }
  })

  const handleClick = (index: number) => {
    if (isSmall) {
      setMobileMenuActive(false)
    }
    scrollTo(`section-${index}`)
  }
  return (
    <StyledHeader
      style={{
        transform: `${mobileMenuActive ? "translateX(100%)" : ""}`,
      }}
    >
      {isSmall && (
        <button
          onClick={() => setMobileMenuActive(!mobileMenuActive)}
          className={`hamburger hamburger--squeeze ${
            mobileMenuActive ? "is-active" : ""
          }`}
          type="button"
        >
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </button>
      )}
      <HeaderSections>
        <Section style={{ marginBottom: 12 }}>
          <a
            href="https://www.instagram.com/elluchadortequila/"
            target="_blank"
          >
            <CircleButton src={instagram} />
          </a>
          <a href="https://www.facebook.com/elluchadortequila/" target="_blank">
            <CircleButton src={facebook} />
          </a>
          <a href="https://twitter.com/LuchadorTequila" target="_blank">
            <CircleButton src={twitter} />
          </a>
          <a href="http://elluchadortequila.blogspot.com/" target="_blank">
            <CircleButton src={blog} />
          </a>
          <a href="https://www.123tequila.com/" target="_blank">
            <ImgButton src={oneTwoThreeLogo} />
          </a>
          <a href="http://www.craneomezcal.com/" target="_blank">
            <ImgButton src={mezcalLogo} />
          </a>
          <a href="https://123spirits.myshopify.com/" target="_blank">
            <ImgButton src={buyButton} />
          </a>
        </Section>
        <Section>
          {[...sections].map((_section, i) => {
            return (
              <SectionMask
                className={activeSection === i ? "active" : "inactive"}
                onClick={() => handleClick(i + 1)}
              >
                {sectionChars[i % sectionChars.length]}
              </SectionMask>
            )
          })}
        </Section>
        <Section>
          <div onClick={() => setInfoOpen(true)}>
            <CircleButton src={info} />
          </div>

          <div onClick={() => toggleMusic()}>
            <MusicButton src={playing ? pause : play} />
            <audio ref={audioRef} loop>
              <source src={luchadorMp3} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </div>
        </Section>
      </HeaderSections>

      <Modal isOpen={infoOpen} onClose={() => setInfoOpen(false)}>
        <div>
          <StyledModalSection>
            <ModalSection>
              <PaperImage image={paper} />
              <ModalContent>
                <Masthead image={masthead} />
                <StyledP color={"black"}>Arandas, Jalisco, Mexico</StyledP>
                <MailLink href="mailto:info@123spirits.com">
                  <StyledP color={"black"}>info@123spirits.com</StyledP>
                </MailLink>
              </ModalContent>
            </ModalSection>
          </StyledModalSection>
        </div>
      </Modal>
    </StyledHeader>
  )
}

const PaperImage = styled(LuchadorImage)`
  position: relative;
  max-width: 100%;
  width: 1140px;
  height: auto;
  ${mediaQueries("phone")`
      width: auto;
      height: 75vh;
      object-fit: cover;
  `};
`
const ModalContent = styled.div`
  position: absolute;
  padding: 0 40px;
  width: 100%;
  max-height: 90%;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  overflow: scroll;

  p {
    text-transform: uppercase;
    line-height: 1em;
  }
`

const Masthead = styled(LuchadorImage)`
  width: 600px;
  margin: auto;
  max-width: 100%;
`

export default Header
