import React, { useState } from "react"
import styled from "styled-components"
import { SectionImageFragment } from "../graphql"

const PlaceholderImage = styled.img`
  position: relative;
  max-height: 100%;
  max-width: 100%;
  margin: 0;
  object-fit: contain;
`

interface LuchadorImageProps {
  image: SectionImageFragment,
  className?: string,
  id?: string,
  sizes?: string,
  onLoaded?: () => void,
}

const LuchadorImage = (props:LuchadorImageProps) => {
  const {image} = props;
  const [loaded, setLoaded] = useState(false);
  const [mounted, setMounted] = useState(false);

  React.useEffect(() => {
    if (!mounted) {
      setMounted(true)
      const fullRes = document.createElement('img');
      fullRes.addEventListener('load', () => {
        setLoaded(true);
        if (props.onLoaded) {
          props.onLoaded();
        }
      })
      fullRes.src = src;
    }
  })

  // @ts-ignore
  const imageProps = Object.assign({}, props)
  delete imageProps.image;

  const src = image.childImageSharp.fluid.src;
  const srcset = image.childImageSharp.fluid.srcSet;

  return (
    <PlaceholderImage 
      {...imageProps} 
      src={!loaded && image.placeholder ? image.placeholder.fluid.base64 : src}
      srcSet={srcset}
    />

    // <StyleComponent id={id}>
    //   <ImageContainer>
    //     <PlaceholderImage className={loaded ? 'hidden' : 'visible'}>
    //       <img {...imageProps} src={LuchadorImage.placeholder ? LuchadorImage.placeholder.fluid.base64 : src} />
    //     </PlaceholderImage>
    //     {mounted && LuchadorImage.placeholder && 
    //       <FullResImage className={loaded ? 'visible' : 'hidden'}>
    //         <img onLoad={() => setLoaded(true)} src={src} srcSet={srcset} {...imageProps} />
    //       </FullResImage>
    //     }
    //   </ImageContainer>
    // </StyleComponent>
  )
}

export default LuchadorImage
