import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import { SectionTwoQueryQuery } from "../../graphql"
import { mediaQueries } from "../../styles/mediaQueries"
import FullScreenComponent from '../full-screen-component'
import FullScreenContainer from '../full-screen-container'
import LuchadorImage from '../luchador-image'
import SectionBottleCopy from "../section-bottle-copy"
import SectionCopy from "../section-copy"

interface SectionTwoProps {
}

const SectionTwo = ({}: SectionTwoProps) => {
    const { background, bottle }: SectionTwoQueryQuery = useStaticQuery(
        graphql`
            query SectionTwoQuery {
                background: file(
                    relativePath: { eq: "section-2/BLANCO_BG_01.jpg"}
                ) {
                    ...backgroundImage
                },
                bottle: file(
                    relativePath: { eq: "section-2/BOTTLE_Blanco_02.png"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 709, quality: 100) {
                            src
                        }
                    }
                }
            }
        `,
    )

    return (
        <StyledSectionTwo id="section-2" backgroundImage={background.childImageSharp.fluid.src}>
            <SectionBottleCopy
                image={bottle}
                color="black"
                reverseOrder={true}
                copy={
                    `El Luchador Blanco opens with notes of fresh agave
                    and delivers bright citrus flavors with a saline finish.
                    This 100 percent Blue Agave spirit is favored by
                    mixologists as a foundation for building authentic
                    cocktails and for enjoying as a fine sipping tequila.`
                }
                textOffsetLeft={-1}
                largerFont={true}
            />
        </StyledSectionTwo>
    )
}

const StyledSectionTwo = styled(FullScreenContainer)`
    display: flex;
    align-items: center;
    justify-content: center;
    
    background-size: 179%;
    background-position: center 19%;

    img {
        ${mediaQueries("phone")`
            transform: translateX(0%);
        `};
    }
    
`

const ImageContainer = styled(FullScreenComponent)`
    align-items: center;
    display: inline-flex;
    justify-content: center;
    padding-bottom: 0;
    padding-top: 0;
    position: relative;
    width: auto;
    max-height: 75vh;
`

const CanImage = styled(LuchadorImage)`
    flex: 0,
`


export default SectionTwo
