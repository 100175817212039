import React from "react"
import styled from "styled-components"
import FullScreenContainer from "../components/full-screen-container"
import Home from "../components/home/home"
import Layout from "../components/layout"
import SectionOne from "../components/section-1/section-1"
import SectionTwo from "../components/section-2/section-2"
import SectionThree from "../components/section-3/section-3"
import SectionFour from "../components/section-4/section-4"
import SectionFive from "../components/section-5/section-5"
import SectionSix from "../components/section-6/section-6"
import SEO from "../components/seo"

import mask1 from '../images/preloader/mask-1.svg';
import mask2 from '../images/preloader/mask-2.svg';
import mask3 from '../images/preloader/mask-3.svg';
import mask4 from '../images/preloader/mask-4.svg';
import mask5 from '../images/preloader/mask-5.svg';

const IndexPage = () => {
  const [loaded, setLoaded] = React.useState(false);

  return (
    <Layout>
      <SEO />
      <Preloader visible={!loaded}>
        <img src={mask1} />
        <img src={mask2} />
        <img src={mask3} />
        <img src={mask4} />
        <img src={mask5} />
      </Preloader>
      <div className="sections">
        <Home onLoaded={() => setLoaded(true)} />
          <LoadedContainer loaded={loaded}>
            <SectionOne />
            <SectionTwo />
            <SectionThree />
            <SectionFour />
            <SectionFive />
            <SectionSix />
          </LoadedContainer>
      </div>
    </Layout>
  )
}

const Preloader = styled.div`
    position: absolute;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    pointer-events: none;
    opacity: ${props => props.visible ? 1 : 0};

    @keyframes blink {
      0% {
        visibility: visible;
      }
      20% {
        visibility: hidden;
      }
      100% {
        visibility: hidden;
      }
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 70px;
      visibility: hidden;

      animation: 2.5s linear infinite blink;

      &:nth-child(2) {
        animation-delay: 0.5s;
      }
      &:nth-child(3) {
        animation-delay: 1s;
      }
      &:nth-child(4) {
        animation-delay: 1.5s;
      }
      &:nth-child(5) {
        animation-delay: 2s;
      }
    }
`
const LoadedContainer = styled.div`
  visibility: ${props => props.loaded ? 'visible' : 'hidden'};
  height: ${props => props.loaded ? 'auto' : 0};
  overflow: hidden;
`

export default IndexPage
