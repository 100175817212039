import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { mediaQueries } from "../styles/mediaQueries";

const StyledModal = styled.div`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: center;
  color: white;
  transition: opacity 0.4s ease-out;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: ${props => props.isOpen ? 1 : 0};
  pointer-events: ${props => props.isOpen ? 'all' : 'none'};
`

const ModalContent = styled.div`
  position: relative;
  max-width: 100%;
`

const ModalBackground = styled.div`
  background: rgba(0,0,0,0.75);
  cursor: pointer;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: -1;
`

const StyledClose = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  transform: translate(100%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  font-family: 'Champion Heavyweight';
  font-size: 24px;
  color: white;
  cursor: pointer;
  border-color: white;
  outline: none;
  border: 2px solid white;
  &:not(:focus-visible) {
    border: none;
  }
  background: none;

  ${mediaQueries("tablet")`
    width: 32px;
    height: 32px;
    font-size: 18px;
  `};
`

interface ModalProps {
  isOpen?: boolean,
  onClose?: () => void,
}

class Modal extends React.Component<ModalProps> {
  private modal;

  constructor(props) {
    super(props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen && !this.props.isOpen) {
      document.body.style.overflow = "hidden";
      this.modal.focus();
    } else if (!nextProps.isOpen && this.props.isOpen) {
      document.body.style.removeProperty("overflow");
    }
  }

  render() {
    const { onClose, isOpen, children } = this.props;
    const portal =
      typeof document !== `undefined`
        ? document.getElementById("modal-container")
        : null;

    const modal = (
      <div ref={(ref) => this.modal = ref} tabIndex="0">
        <StyledModal isOpen={isOpen}>
          <ModalBackground onClick={() => onClose()} />
          <ModalContent>
            <StyledClose onClick={() => onClose()}>x</StyledClose>
            {children}
          </ModalContent>
        </StyledModal>
      </div>
    );

    return portal ? ReactDOM.createPortal(modal, portal) : <div />;
  }
}

export default Modal;
