import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import { SectionSixQueryQuery } from "../../graphql"
import { mediaQueries } from "../../styles/mediaQueries"
import FullScreenComponent from '../full-screen-component'
import FullScreenContainer from '../full-screen-container'
import LuchadorImage from '../luchador-image'
import SectionBottleCopy from "../section-bottle-copy"
import SectionCopy from "../section-copy"
import overlayImg from '../../images/overlay6.jpg';
import Modal from "../modal"
import { StyledH2, StyledP } from "../../styles/typography"
import bottlesFull from "../../images/section-6/modals/bottles.jpg";
import articleFull from "../../images/section-6/modals/article_full.jpg";
import luchadorLogo from "../../images/header/luchador-tequila-logo.svg"

interface SectionSixProps {
}

const SectionSix = ({ }: SectionSixProps) => {
    const { background, text, luchador1, luchador2, luchador3, luchador4, luchador5, luchador6, paper, paper2, cocktail1, cocktail2, bottles, luchadorShirt, article }: SectionSixQueryQuery = useStaticQuery(
        graphql`
            query SectionSixQuery {
                background: file(
                    relativePath: { eq: "section-6/RINGSIDE_NO_TEXT_BG_01.jpg"}
                ) {
                    ...backgroundImage
                },
                text: file(
                    relativePath: { eq: "section-6/RINGSIDE_TEXT_ONLY_01.png"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 581) {
                            src
                        }
                    }
                },
                luchador1: file(
                    relativePath: { eq: "section-6/el_luchador_1.jpg"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 1350) {
                            src
                        }
                    }
                },
                luchador2: file(
                    relativePath: { eq: "section-6/el_luchador_2.jpg"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 1350) {
                            src
                        }
                    }
                },
                luchador3: file(
                    relativePath: { eq: "section-6/el_luchador_3.jpg"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 1350) {
                            src
                        }
                    }
                },
                luchador4: file(
                    relativePath: { eq: "section-6/el_luchador_4.jpg"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 1350) {
                            src
                        }
                    }
                },
                luchador5: file(
                    relativePath: { eq: "section-6/el_luchador_5.jpg"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 1350) {
                            src
                        }
                    }
                },
                luchador6: file(
                    relativePath: { eq: "section-6/el_luchador_6.jpg"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 1350) {
                            src
                        }
                    }
                },
                paper: file(
                    relativePath: { eq: "section-6/modals/paper.png"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 1502) {
                            src
                        }
                    }
                    placeholder: childImageSharp {
                        fluid(base64Width: 1502, pngCompressionSpeed: 1, quality: 50) {
                          base64
                        }
                      }
                },
                paper2: file(
                    relativePath: { eq: "section-6/modals/paper2.png"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 1400) {
                            src
                        }
                    }
                    placeholder: childImageSharp {
                        fluid(base64Width: 1400, pngCompressionSpeed: 1, quality: 80) {
                          base64
                        }
                      }
                },
                paper2Placeholder: file(
                    relativePath: { eq: "section-6/modals/paper2.png"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 2115) {
                            src
                        }
                    }
                },
                cocktail1: file(
                    relativePath: { eq: "section-6/modals/cocktail_1.jpg"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 704) {
                            src
                        }
                    }
                },
                cocktail2: file(
                    relativePath: { eq: "section-6/modals/cocktail_2.jpg"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 710) {
                            src
                        }
                    }
                },
                bottles: file(
                    relativePath: { eq: "section-6/modals/bottles.jpg"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 1600) {
                            src
                        }
                    }
                },
                luchadorShirt: file(
                    relativePath: { eq: "section-6/modals/luchador_shirt.jpg"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 1280) {
                            src
                        }
                    }
                },
                article: file(
                    relativePath: { eq: "section-6/modals/article.png"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 1877) {
                            src
                        }
                    }
                    placeholder: childImageSharp {
                        fluid(base64Width: 1877, pngCompressionSpeed: 1, quality: 80) {
                          base64
                        }
                      }
                },
            }
        `,
    )

    const [active, setActive] = React.useState(undefined);
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        if (active !== undefined && !open) {
            setOpen(true)
        }
    }, [active])

    React.useEffect(() => {
        if (!open) {
            setTimeout(() => setActive(undefined), 500)
        }
    }, [open])

    const modals = [
        <ModalOne bg={paper} />,
        <ModalTwo bg={paper2} cocktail1={cocktail1} cocktail2={cocktail2} />,
        <ModalThree image={bottles} />,
        <ModalFour storeImage={luchadorShirt} bg={paper2} />,
        <ModalFive image={article} />,
        <ModalSix bg={paper2} />,
    ]

    return (
        <StyledSectionSix id="section-6" backgroundImage={background.childImageSharp.fluid.src}>
            <ImageContainer>
                <ImageBox tabIndex="0" onKeyPress={(event) => {if (event.key === 'Enter') {setActive(0)}}} onClick={() => setActive(0)}>
                    <BoxImage image={luchador1} style={{ objectPosition: 'center 35%' }} />
                    <div className="box-title"><StyledP>El Luchador</StyledP></div>
                </ImageBox>
                <ImageBox tabIndex="0" onKeyPress={(event) => {if (event.key === 'Enter') {setActive(1)}}} onClick={() => setActive(1)}>
                    <BoxImage image={luchador2} />
                    <div className="box-title"><StyledP>Cocktails</StyledP></div>
                </ImageBox>
                <ImageBox tabIndex="0" onKeyPress={(event) => {if (event.key === 'Enter') {setActive(2)}}} onClick={() => setActive(2)}>
                    <BoxImage image={luchador3} />
                    <div className="box-title"><StyledP>Tequilas</StyledP></div>
                </ImageBox>
                <ImageBox tabIndex="0" onKeyPress={(event) => {if (event.key === 'Enter') {setActive(3)}}} onClick={() => setActive(3)}>
                    <BoxImage image={luchador4} />
                    <div className="box-title"><StyledP>Swag</StyledP></div>
                </ImageBox>
                <ImageBox tabIndex="0" onKeyPress={(event) => {if (event.key === 'Enter') {setActive(4)}}} onClick={() => setActive(4)}>
                    <BoxImage image={luchador5} />
                    <div className="box-title"><StyledP>Press</StyledP></div>
                </ImageBox>
                <ImageBox tabIndex="0" onKeyPress={(event) => {if (event.key === 'Enter') {setActive(5)}}} onClick={() => setActive(5)}>
                    <BoxImage image={luchador6} />
                    <div className="box-title"><StyledP>Contact</StyledP></div>
                </ImageBox>
            </ImageContainer>
            <TextImage image={text} />
            <Modal isOpen={open} onClose={() => setOpen(false)}>
                {modals[active]}
            </Modal>
        </StyledSectionSix>
    )
}

const StyledSectionSix = styled(FullScreenContainer)`
    display: grid;
    align-items: center;
    justify-content: center;

    background-size: 148%;
    background-position: center 19%;
`

const ImageContainer = styled.div`
    width: calc((100vw - 80px) * 0.46);
    height: 87.5%;
    position: absolute;
    top: 50%;
    transform: translate(0%, -49.5%);
    left: calc(80px + 9vw);
    display: grid;
    grid-gap: 1.5vw;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);

    ${mediaQueries("tablet")`
        width: calc(100% - 80px);
        left: 40px;
        height: 75%;
        top: 57.5%;
    `};
`

const ImageBox = styled.div`
    position: relative;
    cursor: pointer;

    .box-title {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        pointer-events: none;
        z-index: 1;
        transition: opacity 0.1s linear;
        opacity: 0;

        p {
            color: black;
            line-height: 1em;
            text-transform: uppercase;
            padding: 1em 0 0;
            margin: 0;
        }

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: black;
            background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
            z-index: -1;
            transform: translateY(1px);
        }
    }

    &:hover {
        img {
            opacity: 0.8;
        }

        .box-title {
            opacity: 1;
        }
    }
`

const BoxImage = styled(LuchadorImage)`
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center 15%;
    cursor: pointer;
    transition: opacity 0.1s linear;
    opacity: 1;
`

const TextImage = styled(LuchadorImage)`
    position: absolute;
    width: 23vw;
    top: 50%;
    left: calc(((100vw - 80px) * 0.46) + calc(80px + 9vw) + 6.4vw);
    transform: translateY(-108%);

    ${mediaQueries("tablet")`
        width: auto;
        left: 50%;
        height: calc(15% - 40px);
        top: 40px;
        transform: translateX(-50%);
    `};
`

const ModalContent = styled.div`
    position: relative;
    max-width: calc(100vw - 160px);
    max-height: calc(100vh - 80px);
    display: flex;

    ${mediaQueries("phone")`
        max-width: calc(100vw - 80px);
        max-height: calc(100vh - 40px);
    `};

    a {
        color: black;
        &:hover {
            color: gray;
            p {
                color: gray;
            }
        }
    }
`

const ModalUi = ({ active, length, setActive }) => {
    return (
        <StyledModalUi>
            {active !== 0 && <LeftArrow onClick={() => setActive(active - 1)}></LeftArrow>}
            {active !== (length - 1) && <RightArrow onClick={() => setActive(active + 1)}></RightArrow>}
            <Dots>
                {Array.apply(null, Array(length)).map((_x, i) =>
                    <Dot data-active={i === active} onClick={() => setActive(i)} />
                )}
            </Dots>
        </StyledModalUi>
    )
}

const StyledModalUi = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    > * {
        pointer-events: all;
    }
`

const LeftArrow = styled.button`
    position: absolute;
    top: 50%;
    right: 100%;
    width: 40px;
    height: 40px;
    border: none;
    background: none;
    cursor: pointer;
    transform: translate(-50%, -50%);
    padding: 0;
    &:after {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        top: 50%;
        left: 50%;
        transform: translate(-15%, -50%) rotate(-135deg);
        border: 2px solid white;
        border-width: 2px 2px 0 0;
    }


    ${mediaQueries("phone")`
        right: 96%;
    `};
`
const RightArrow = styled.button`
    position: absolute;
    top: 50%;
    left: 100%;
    width: 40px;
    height: 40px;
    background: none;
    cursor: pointer;
    border: none;
    transform: translate(50%, -50%);
    padding: 0;

    &:after {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        top: 50%;
        left: 50%;
        transform: translate(-85%, -50%) rotate(-135deg);
        border: 2px solid white;
        border-width: 0 0 2px 2px;
    }

    ${mediaQueries("phone")`
        left: 96%;
    `};
`

const Dots = styled.div`
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 100%);
    display: flex;
`

const Dot = styled.div`
    display: block;
    background: none;
    border: 2px solid white;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin: 0 4px;
    cursor: pointer;

    &[data-active=true] {
        background: white;
    }
`

const ModalOne = (props) => {
    const [slide, setSlide] = React.useState(0);
    const slides = [
        {
            title: 'The Legend',
            copy: `The use of the mascara or Lucha Libre mask has been a part 
            of Lucha Libre since its inception. However, the practice was 
            popularized by Santo, El Enmascarado de Plata, or "Saint, the 
            Silver Masked Man." Santo made his debut in the summer of 1942, and 
            quickly captured the public's fascination with his fighting ability 
            and mysterious secret identity. He lied most of his entire life 
            never showing his face. Santo was clearly the greatest Luchador of 
            all time, and his impact on Mexican culture and society was 
            immeasurable.`
        },
        {
            title: 'The Mask',
            copy: `The use of the mascara or Lucha Libre mask has been a part 
            of Lucha Libre since its inception. However, the practice was 
            popularized by Santo, El Enmascarado de Plata, or "Saint, the 
            Silver Masked Man." Santo made his debut in the summer of 1942, and 
            quickly captured the public's fascination with his fighting ability 
            and mysterious secret identity. He lied most of his entire life 
            never showing his face. Santo was clearly the greatest Luchador of 
            all time, and his impact on Mexican culture and society was 
            immeasurable.`
        },
        {
            title: 'The Wrestler',
            copy: `The use of the mascara or Lucha Libre mask has been a part 
            of Lucha Libre since its inception. However, the practice was 
            popularized by Santo, El Enmascarado de Plata, or "Saint, the 
            Silver Masked Man." Santo made his debut in the summer of 1942, and 
            quickly captured the public's fascination with his fighting ability 
            and mysterious secret identity. He lied most of his entire life 
            never showing his face. Santo was clearly the greatest Luchador of 
            all time, and his impact on Mexican culture and society was 
            immeasurable.`
        }
    ]
    const activeSlide = slides[slide];
    return (
        <ModalContent>
            <PaperImage image={props.bg} />
            <PaperText>
                <StyledH2>{activeSlide.title}</StyledH2>
                <StyledP>{activeSlide.copy}</StyledP>
            </PaperText>
            <ModalUi active={slide} length={slides.length} setActive={setSlide} />
        </ModalContent>
    )
}

const PaperImage = styled(LuchadorImage)`
    position: relative;
    max-width: 100%;
    width: 1140px;
    height: auto;

    ${mediaQueries("phone")`
        width: auto;
        height: 75vh;
        object-fit: cover;
    `};
`

const PaperText = styled.div`
    position: absolute;
    padding: 0 40px;
    width: 100%;
    max-height: 90%;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    overflow: scroll;

    text-align: center;
    color: black;

    ${mediaQueries("phone")`
        padding: 40px;
    `};

    h2 {
        text-transform: uppercase;
        margin: 0 auto 0.5em;

        @media (max-width: 1300px) {
            font-size: calc((100vw - 80px) * 0.0435);
        }

        ${mediaQueries("phone")`
            font-size: 40px
        `};
    }

    p {
        color: black;
        line-height: 1.4;
        font-size: 34px;

        @media (max-width: 1300px) {
            font-size: calc((100vw - 80px) * 0.029);
        }

        ${mediaQueries("phone")`
            font-size: 28px
        `};
    }
`

const ModalTwo = ({ bg ,cocktail1, cocktail2 }) => {
    const [slide, setSlide] = React.useState(0);
    const slides = [
        {
            recipe: `
                <h2>Santo Contra Los Zombies<br />
                (Santo vs the Zombies)</h2>
                <p><span class="red">2 OZ El Luchador Blanco Tequila</span><br/>
                1/4 OZ D’Aristi<br/>
                1/2 OZ Combier L’Original<br/>
                2 OZ Orange Juice<br/>
                1 OZ Lemon Juice<br/>
                1/2 OZ Pomegranate Juice<br/><br/>
                Glass: Hurricane Glass<br/>
                Garnish: Orange Moon and Pineapple Leaf<br/>
                Ice: Crushed<br/>
                <span class="byline">Cocktail by Jasamie Chai and Oyamel DC</span>
            `,
            image: <CocktailImage image={cocktail1} />,
        },
        {
            recipe: `
                <h2>Santo en Atacon las Brujas<br />
                (Santo in "The Witches Attack")</h2>
                <p><span class="red">2 OZ El Luchador Blanco 110 Proof</span><br/>
                Grapefruit Lavender Soda on the Rocks<br/>
                <span class="byline">Cocktail by Jasamie Chai and Oyamel DC</span>
            `,
            image: <CocktailImage image={cocktail2} />,
        },
    ]
    const activeSlide = slides[slide];
    const isEven = (slide + 1) % 2 === 0;
    return (
        <ModalContent>
            <div style={{transform: `scaleY(${isEven ? -1 : 1}) rotate(${180 * (isEven ? 1 : 0)}deg)`}}>
                <PaperImage image={bg} />
            </div>
            <Recipe>
                <CocktailRecipeSection>
                    <StyledP dangerouslySetInnerHTML={{__html: activeSlide.recipe}} />
                </CocktailRecipeSection>
                <CocktailImageSection>
                    {activeSlide.image}
                </CocktailImageSection>
                {/* <StyledH2>{activeSlide.title}</StyledH2>
                <StyledP>{activeSlide.copy}</StyledP> */}
            </Recipe>
            <ModalUi active={slide} length={slides.length} setActive={setSlide} />
        </ModalContent>
    )
}

const Recipe = styled.div`
    display: flex;
    position: absolute;
    padding: 0 40px;
    width: 100%;
    max-height: 90%;
    top: 50%;
    left: 0;
    transform: translateY(-50%);

    text-align: center;
    align-items: flex-start;

    overflow: scroll;
    ::-webkit-scrollbar {
        width: 0;
        background: transparent;
    }


    h2 {
        color: black;
        line-height: 1em;
        font-weight: 400;
    }

    p {
        color: black;
        letter-spacing: 0;

        .red {
            color: #cc092f;
        }

        .byline {
            font-size: 0.75em;
        }
    }

    @media screen and (max-width: 1100px) {
        h2 {
            font-size: calc((100vw - 240px) * 0.05)
        }
        p {
            font-size: calc((100vw - 240px) * 0.03)
        }
    }

    @media screen and (max-width: 768px) {
        flex-direction: column;
        h2 {
            font-size: 32px
        }
        p {
            font-size: 24px;
        }
    }
`

const CocktailRecipeSection = styled.div`
    flex: 1 1 100%;
    min-height: 100%;
    margin: auto;

    @media screen and (max-width: 768px) {
        position: relative;
        min-height: auto;
        flex: 1;
    }
    
`

const CocktailImageSection = styled.div`
    position: sticky;
    top: 0;
    flex: 0 1 auto;
    max-height: 100%;

    @media screen and (max-width: 768px) {
        position: relative;
        flex: 1;
        margin-top: 20px;
    }

`

const CocktailImage = styled(LuchadorImage)`
    max-height: 100%;
`

const ModalThree = ({ image }) => {
    return (
        <a href={bottlesFull} target="_blank">
            <ModalContent style={{background: 'white'}}>
                <LuchadorImage image={image} />
            </ModalContent>
        </a>
    )
}

const ModalFour = ({ bg, storeImage }) => {
    return (
        <ModalContent>
            <PaperImage image={bg} />
            <StoreContent>
                <StoreImageContainer>
                    <StoreImage image={storeImage} />
                </StoreImageContainer>
                <StoreCopy>
                    <StyledP>Sizes: M, L, XL, XXL</StyledP>
                    <StyledP>Price: $25 includes shipping</StyledP>
                    <StyledP>Contact <a href="mailto:info@123spirits.com">info@123spirits.com</a> to order</StyledP>
                </StoreCopy>
            </StoreContent>
        </ModalContent>
    )
}

const StoreContent = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(75% - 20px);
    height: calc(75% - 20px);
    justify-content: center;
    align-items: center;
`

const StoreImageContainer = styled.div`
    position: relative;
    flex: 1;
    width: 100%;
`

const StoreImage = styled(LuchadorImage)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`

const StoreCopy = styled.div`
    flex: 0;
    margin-top: 24px;
    p {
        color: black;
        margin: 0;
        font-size: 24px;
    }
`

const ModalFive = ({ image }) => {
    return (
        <a href={articleFull} target="_blank">
            <ModalContent>
                <LuchadorImage image={image} />
            </ModalContent>
        </a>
    )
}

const ModalSix = ({ bg }) => {
    return (
        <ModalContent>
            <PaperImage image={bg} />
            <ContactContent>
                <ContactText>
                    <StyledP>Arandas, Jalisco, Mexico</StyledP>
                </ContactText>
                <ContactImage>
                <img src={luchadorLogo} />
                </ContactImage>
                <ContactText>
                    <a href="mailto:info@123spirits.com"><StyledP>info@123spirits.com</StyledP></a>
                </ContactText>
            </ContactContent>
        </ModalContent>
    )
}

const ContactContent = styled(PaperText)`
    display: flex;
    height: 100%;
`

const ContactText = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
        text-transform: uppercase;    
    }
`

const ContactImage = styled.div`
    flex: 0 0 auto;
    margin: 0 40px;
    img {
        height: 100%;
    }
`

export default SectionSix
