import React from "react"
import styled from "styled-components"
import { breakpoints, mediaQueries } from "../styles/mediaQueries"
import { StyledP } from "../styles/typography"
import { useWindowResize } from "../utilities/hooks"
import { getTransformStyleBasedOnBoxSize } from "../utilities/luchador-utils"
import FullScreenComponent from "./full-screen-component"
import LuchadorImage from "./luchador-image"
import SectionOneHeadline from "./section-3/section-3-headline"
import SectionCopy from "./section-copy"

interface SectionBottleCopyProps {
  copy?: string
  headlineComponent?: JSX.Element
  image?: any
  color?: string
  reverse?: boolean
  offsetLeft?: number
  offsetTop?: number
  textOffsetLeft?: number
  textOffsetTop?: number
  largerFont?: boolean
  textAdjust?: number
}

const Container = styled.div`
  position: relative;
  padding-left: 80px;

  ${mediaQueries("medium")`
        left: 0px;
        width: 100%;
    `};

  ${mediaQueries("tablet")`
        padding-left: 0px;
    `};
`

const ImageContainer = styled(FullScreenComponent)`
  align-items: center;
  display: inline-flex;
  justify-content: center;
  position: relative;
  flex-direction: ${(props) => (props.reverse ? "row-reverse" : "row")};
  padding: 0;

  ${mediaQueries("medium")`
        max-height: none;
        flex-direction: column-reverse;
        padding: 64px 40px;
    `};
`

const CanImage = styled(LuchadorImage)`
  transform: translate(-5%, 4.25%);
  height: 101vh;
  max-height: inherit;

  ${mediaQueries("medium")`
        height: 75vh;
        margin: auto;
    `};
`
const StyledCopyContainer = styled.div`
  width: calc(((100vw - 0px) / 2) - (6vw + 190px));
  position: absolute;
  top: 54.2%;
  left: 100%;
  transform: translateY(-50%);

  ${mediaQueries("medium")`
        position: relative;
        top: initial;
        left: initial;
        transform: initial;
        width: 100%;
    `};

  p {
    text-align: left;
    font-size: calc(
      ((((100vw - 0px) / 2) - (7vw + 190px)) * 0.055) *
        ${(props) => props.textAdjust || 1}
    );

    ${mediaQueries("medium")`
            font-size: 24px;
            text-align: center;
        `};
  }

  &.reverse {
    left: initial;
    right: 105%;
    width: calc(((100vw - 0px) / 2) - (7vw + 170px));
    top: 52.5%;

    ${mediaQueries("medium")`
            position: relative;
            top: initial;
            right: initial;
            transform: initial;
            width: 100%;
        `};

    p {
      text-align: right;
      font-size: calc(
        ((((100vw - 0px) / 2) - (7vw + 170px)) * 0.067) *
          ${(props) => props.textAdjust || 1}
      );

      ${mediaQueries("medium")`
                font-size: 24px;
                text-align: center;
            `};
    }
  }
`

const StyledCopy = styled(StyledP)`
  line-height: ${(props) => (props.reverse ? "1.25em" : "1.35em")};
`

const SectionBottleCopy = (props: SectionBottleCopyProps) => {
  const { height = 0, width = 0 } = useWindowResize()
  const smallWidth = width < breakpoints.medium
  const transformStyle = {
    width: smallWidth ? width : width - 80,
    height: smallWidth ? "auto" : height,
    minHeight: smallWidth ? height : "initial",
  }

  const textTransformStyle = smallWidth
    ? {}
    : {
        transform: `translate(${props.textOffsetLeft ||
          0}%, ${props.textOffsetTop || 0}%)`,
      }

  return (
    <Container style={transformStyle}>
      <ImageContainer
        reverse={props.reverseOrder}
        largerFont={props.largerFont}
      >
        <div style={{ position: "relative" }}>
          <CanImage image={props.image} />
          <StyledCopyContainer
            className={props.reverse ? "reverse" : ""}
            textAdjust={props.textAdjust}
          >
            <StyledCopy
              color={props.color}
              reverse={props.reverse}
              style={textTransformStyle}
              dangerouslySetInnerHTML={{ __html: props.copy }}
            />
          </StyledCopyContainer>
        </div>
      </ImageContainer>
    </Container>
  )
}

export default SectionBottleCopy
