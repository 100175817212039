import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import { SectionThreeQueryQuery } from "../../graphql"
import { mediaQueries } from "../../styles/mediaQueries"
import FullScreenComponent from '../full-screen-component'
import FullScreenContainer from '../full-screen-container'
import LuchadorImage from '../luchador-image'
import SectionBottleCopy from "../section-bottle-copy"
import SectionCopy from "../section-copy"
import SectionThreeHeadline from "./section-3-headline"
import overlayImg from '../../images/overlay.jpg';

interface SectionThreeProps {
}

const SectionThree = ({}: SectionThreeProps) => {
    const { background, bottle }: SectionThreeQueryQuery = useStaticQuery(
        graphql`
            query SectionThreeQuery {
                background: file(
                    relativePath: { eq: "section-3/REPOSADO_BG_01.jpg"}
                ) {
                    ...backgroundImage
                },
                bottle: file(
                    relativePath: { eq: "section-3/BOTTLE_Reposado_02.png"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 893, quality: 100) {
                            src
                        }
                    }
                },
            }
        `,
    )

    return (
        <StyledSectionThree id="section-3" backgroundImage={background.childImageSharp.fluid.src}>
            <SectionBottleCopy
                image={bottle}
                headlineComponent={<SectionThreeHeadline />}
                color="black"
                copy={
                    `Roasted agave, bright citrus and vanilla
                    are the hallmarks of El Luchador Reposado
                    which is the second stage of El Luchador’s
                    evolution and where it gains complexity
                    and finesse from aging for four to six
                    months in white oak barrels.`
                }
                reverse={true}
            />
        </StyledSectionThree>
    )
}

const StyledSectionThree = styled(FullScreenContainer)`
    display: flex;
    align-items: center;
    justify-content: center;

    background-size: 148%;
    background-position: center 19%;

    img {
        ${mediaQueries("tablet")`
            transform: translateX(7.5%);
        `};
        ${mediaQueries("phone")`
            transform: translateX(-1%);
        `};
    }
`

export default SectionThree
