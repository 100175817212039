import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import { SectionFourQueryQuery } from "../../graphql"
import { mediaQueries } from "../../styles/mediaQueries"
import FullScreenComponent from "../full-screen-component"
import FullScreenContainer from "../full-screen-container"
import LuchadorImage from "../luchador-image"
import SectionBottleCopy from "../section-bottle-copy"
import SectionCopy from "../section-copy"
import overlayImg from "../../images/overlay2.jpg"

interface SectionFourProps {}

const SectionFour = ({}: SectionFourProps) => {
  const { background, bottle }: SectionFourQueryQuery = useStaticQuery(
    graphql`
      query SectionFourQuery {
        background: file(relativePath: { eq: "section-4/ANEJO_BG_01.jpg" }) {
          ...backgroundImage
        }
        bottle: file(relativePath: { eq: "section-4/BOTTLE_Anejo_02.png" }) {
          childImageSharp {
            fluid(maxWidth: 709, quality: 100) {
              src
            }
          }
        }
      }
    `,
  )

  return (
    <StyledSectionFour
      id="section-4"
      backgroundImage={background.childImageSharp.fluid.src}
    >
      <SectionBottleCopy
        image={bottle}
        color="black"
        copy={`Our Anejo spends a total of fourteen to sixteen months
                    resting in white oak barrels. That additional time in
                    French white oak adds increasing layers of complexity with
                    notes of deeply roasted agave, caramelized citrus and dark
                    vanilla.`}
        textOffsetLeft={5}
      />
    </StyledSectionFour>
  )
}

const StyledSectionFour = styled(FullScreenContainer)`
  display: flex;
  align-items: center;
  justify-content: center;

  background-size: 148%;
  background-position: center 19%;

  img {
    ${mediaQueries("tablet")`
            transform: translateX(7.5%);
        `};
    ${mediaQueries("phone")`
            transform: translateX(-2.5%);
        `};
  }
`

export default SectionFour
