import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import { SectionFiveQueryQuery } from "../../graphql"
import { mediaQueries } from "../../styles/mediaQueries"
import FullScreenComponent from '../full-screen-component'
import FullScreenContainer from '../full-screen-container'
import LuchadorImage from '../luchador-image'
import SectionBottleCopy from "../section-bottle-copy"
import SectionCopy from "../section-copy"
import overlayImg from '../../images/overlay3.jpg';

interface SectionFiveProps {
}

const SectionFive = ({}: SectionFiveProps) => {
    const { background, bottle }: SectionFiveQueryQuery = useStaticQuery(
        graphql`
            query SectionFiveQuery {
                background: file(
                    relativePath: { eq: "section-5/REPOSADO_BG_01.jpg"}
                ) {
                    ...backgroundImage
                },
                bottle: file(
                    relativePath: { eq: "section-5/BOTTLE_110proof_02.png"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 709, quality: 100) {
                            src
                        }
                    }
                },
            }
        `,
    )

    return (
        <StyledSectionFive id="section-5" backgroundImage={background.childImageSharp.fluid.src}>
            <SectionBottleCopy
                image={bottle}
                color="black"
                textOffsetLeft={-4.5}
                textOffsetTop={14}
                copy={
                    `The addition of a distill-proof Blanco tequila, a 
                    110-proof spirit ideally suited as a foundation 
                    for building authentic cocktails and for 
                   enjoying as an agave digestif, elevates the El 
                   Luchador portfolio. This high proof Blanco is 
                   robust and complex with notes of fresh agave, 
                   bright citrus a slightly smoky saline finish.`
                }
                reverse={true}
            />
        </StyledSectionFive>
    )
}

const StyledSectionFive = styled(FullScreenContainer)`
    display: flex;
    align-items: center;
    justify-content: center;

    background-size: 148%;
    background-position: center 19%;

    img {
        ${mediaQueries("tablet")`
            transform: translateX(7.5%);
        `};
        ${mediaQueries("phone")`
            transform: translateX(-2.5%);
        `};
    }
`

export default SectionFive
