import styled from "styled-components"
import { mediaQueries } from "./mediaQueries";

export const StyledH1 = styled.h1`
    color: white;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 0;
    font-weight: 100;
`;

export const StyledH2 = styled.h2`
    font-family: 'Champion Heavyweight', Helvetica;
    font-size: 64px;
    line-height: 1.2;
`

export const StyledP = styled.p`
    color: white;
    font-family: 'Champion Featherweight', Helvetica;
    text-align: center;
    line-height: 1.2em;
    margin: 0.25em 0;
    color: ${props => props.color || 'white'};
    font-size: 40px;
    letter-spacing: 0.0385em;
    line-height: 1.225em;

    span.justified {
        display: block;
        text-align: justify;

        &:after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 0px;
        }
    }

    span.post-justified {
        display: inline-block;
        margin-top: -1em;
    }

`