import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import { SectionOneQueryQuery } from "../../graphql"
import { mediaQueries } from "../../styles/mediaQueries"
import { StyledP } from "../../styles/typography"
import { useWindowResize } from "../../utilities/hooks"
import FullScreenComponent from '../full-screen-component'
import FullScreenContainer from '../full-screen-container'
import LuchadorImage from '../luchador-image'
import SectionCopy from "../section-copy"
import SectionOneHeadline from "./section-1-headline"

interface SectionOneProps {
}

const SectionOne = ({ }: SectionOneProps) => {
    const { background, about, overlay }: SectionOneQueryQuery = useStaticQuery(
        graphql`
            query SectionOneQuery {
                background: file(
                    relativePath: { eq: "section-1/ABOUT_BG_01.jpg"}
                ) {
                    ...backgroundImage
                },
                about: file(
                    relativePath: { eq: "section-1/ABOUT_TEXT_ONLY_01.png"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 192) {
                            src
                        }
                    }
                },
                overlay: file(
                    relativePath: { eq: "overlay1.jpg"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 1500) {
                            src
                        }
                    }
                },
            }
        `,
    )

    return (
        <StyledSectionOne id="section-1" backgroundImage={background.childImageSharp.fluid.src}>
            <Copy>
                <span className="intro-line">INSPIRED BY THE FAMED MASKED WRESTLERS OF MEXICO, </span> 
                tequilero David Ravandi has created El Luchador, a unique,
                artisanal portfolio of outstanding tequila expressions:
                Still Strength Blanco, Blanco, Reposado and Anejo.<br/><br/>

                Since his entrée into the artisanal tequila business in
                the mid-1990s, Ravandi has ushered several brands to
                international success. His El Luchador portfolio was
                inspired by the instantly recognizable-masks and the
                acrobatic showmanship of Mexico’s colorful lucha libre 
                wrestlers. The connection between tequila and lucha libre -
                two of Mexico’s most treasured cultural
                institutions – represents a long-standing tradition,
                one that inspired El Luchador
            </Copy>

            <AboutImage image={about} />
        </StyledSectionOne>
    )
}

const StyledSectionOne = styled(FullScreenContainer)`
    background-position: 9vw top;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    ${mediaQueries("tablet")`
        justify-content: center;
    `};
`

const AboutImage = styled(LuchadorImage)`
    position: absolute;
    width: 10.5vw;
    top: 50%;
    left: calc(((100vw - 80px) * 0.42) + calc(80px + 7vw));
    transform: translateY(-108%);

    ${mediaQueries("tablet")`
        left: 75%;
    `};

    ${mediaQueries("phone")`
        visibility: hidden;
    `};
`

const Copy = styled(StyledP)`
    width: 50vw;
    color: black;
    text-transform: none;
    margin-left: calc(80px + 4vw);
    margin-top: 3vh;
    width: calc((100vw - 80px) * 0.42);
    font-size: calc((100vw - 80px) * 0.022);

    .intro-line {
        font-size: calc((100vw - 80px) * 0.025);
        letter-spacing: 0.0365em;
    }
    

    ${mediaQueries("maxHeadline")`
    `};

    ${mediaQueries("tablet")`
        margin-left: initial;
    `};

    ${mediaQueries("phone")`
        width: 100%;
        padding: 20px;
        font-size: 24px;

        .intro-line {
            font-size: 26px;
        }
    `};
`


export default SectionOne
